import { ReactNode, useState } from "react";
import { Modal, ModalProps } from "react-daisyui";

import { cn } from "~/utilities/cn";

export type UseModalResp = {
  modal: ReactNode;
  closeModal: () => void;
  openModal: () => void;
};

export type UseModalProps = ModalProps & {
  allowBackdropClick?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  className?: string;
};

export const useModal = ({
  children,
  allowBackdropClick = true,
  onOpen,
  className = "",
  onClose,
}: UseModalProps): UseModalResp => {
  const [open, setOpen] = useState<boolean>(false);

  const closeModal = () => {
    onClose && onClose();
    setOpen(false);
  };

  const openModal = () => {
    onOpen && onOpen();
    setOpen(true);
  };

  const modal: ReactNode = (
    <Modal
      open={open}
      backdrop={allowBackdropClick}
      className={cn(className, open ? "block" : "hidden")}
    >
      {children}
    </Modal>
  );

  return {
    closeModal,
    openModal,
    modal,
  };
};
