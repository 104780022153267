import ButtonResize from "../ButtonResize";

import { cn } from "~/utilities/cn";
import { EIcon } from "~/utilities/enums/Icons";

export interface VirtualViewProps {
  iframe?: string;
  onClick?: () => void;
}
function VirtualView(props: VirtualViewProps) {
  const { iframe = "", onClick } = props;
  return (
    <>
      <iframe
        title="virtual-view"
        className="h-full w-full"
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        style={{ border: 0, background: "black" }}
        src={iframe}
      />
      <ButtonResize
        onClick={onClick}
        className={cn(
          "fixed left-3 top-3 z-100 flex-row-reverse transition-all duration-500 hover:w-21.5 hover:p-3"
        )}
        variant="short"
        text="Back"
        icon={EIcon.BackIcon}
      />
    </>
  );
}

export default VirtualView;
