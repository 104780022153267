import { Link } from "@remix-run/react";

import { Icon } from "~/components/atoms/Icons";
import ImageResponsive from "~/components/atoms/ImageResponsive";
import Typo from "~/components/atoms/Typo";
import { IMediaImageUrls } from "~/entities/media";
import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { EIcon } from "~/utilities/enums/Icons";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface VirtualCardProps {
  data?: {
    name?: string;
    sqft?: string;
    types?: string;
    location?: string;
    url?: string;
    urls?: IMediaImageUrls;
  };
  className?: string;
  thumbnailClass?: string;
  onClick?: () => void;
}

export default function VirtualCard(props: VirtualCardProps) {
  const { data, onClick, className, thumbnailClass } = props;

  return (
    <div className={cn("flex w-full flex-col gap-y-3", className)}>
      <button
        onClick={onClick}
        className={cn(
          "relative h-full overflow-hidden rounded-xl",
          thumbnailClass
        )}
      >
        <ImageResponsive
          imageData={data?.urls}
          className="aspect-[400/224] h-auto object-center"
        />
        <div className="absolute bottom-3 right-3 rounded-[4px] bg-color px-2 py-1 opacity-80">
          <Icon TypeIcon={EIcon.DegIcon} />
        </div>
      </button>
      {(data?.name || data?.types || data?.location) && (
        <Link to={data?.url ?? ""}>
          <div className="flex flex-col gap-y-2">
            <Typo
              color={ETypoColor.TEXT}
              tag={ETypoTag.P}
              variant={ETypoVariant.HEADER_16}
              className="font-bold"
            >
              {data?.name}
            </Typo>
            {data?.types && (
              <div className="flex items-center justify-between">
                <Typo
                  color={ETypoColor.TEXT}
                  tag={ETypoTag.P}
                  variant={ETypoVariant.BODY_TITLE_16}
                  className="font-bold"
                >
                  {data?.types}
                </Typo>
              </div>
            )}
            {data?.location && (
              <Typo
                color={ETypoColor.TEXT}
                tag={ETypoTag.SPAN}
                variant={ETypoVariant.BODY_TITLE_14}
                className="opacity-50"
              >
                {data?.location}
              </Typo>
            )}
          </div>
        </Link>
      )}
    </div>
  );
}
